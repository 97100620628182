import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../assets/icons/back-arrow.svg'; 

export interface LinkProps {
  to: string,
  icon: React.ReactElement,
  text: string,
  notifications?: number,
  onNavigate?: () => void
}

interface BackLinkProps {
  text: string,
  hideTextOnMob?: boolean,
  onClick?: () => void
}

export const BackLink = (props: BackLinkProps) => {

  let navigate = useNavigate();

  return (
    <div className="back-link" onClick={() => props.onClick ? props.onClick() : navigate(-1)}>
      <BackArrow />
      { 
        !(props.hideTextOnMob && (window.innerWidth < 800)) && <div className="label--gray">{props.text}</div>
      }
    </div>
  )
}

const Link = (props: LinkProps) => {

  return (
    <li>
      <NavLink to={props.to} onClick={props.onNavigate}>
        <div className='icon-wrapper'>
          {props.icon}   
        </div>
        {props.text}
      </NavLink>
    </li>
  );
}

export default Link;
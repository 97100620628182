import { useAppDispatch } from "../../../app/hooks";
import { newMessage } from "../../../features/messages/messagesSlice";
import PlacesScheme from "../../../features/places/placesEnum";
import { deleteStaff } from "../../../features/staff/staffSlice";
import { roles } from "../../../helpers";

export const ManagmentSection = (props: {places: Record<string, number>, email: string, formated_places: Record<string, PlacesScheme>}) => {
  
  let {places, formated_places} = props;
  const dispatch = useAppDispatch();
  
  const removeManagment = async (place_id: string, email: string) => {
    try {
      await dispatch(deleteStaff({email, place_id}));
      dispatch(newMessage({content: "Uspješno ste makli ste ulogu korisniku.", type: "success"}));
    }
    catch {
      dispatch(newMessage({content: "Nešto je pošlo po zlu.", type: "error"}));
    }
  }
  return (
  <>
  <div className="label input__label">Upravljani klubovi</div>
  <div className="staff-details__role input-wrapper">
    { 
      Object.entries(places).map(([key]) => { return (
      <div className="all-users" key={key}>
        <div className="all-users__box">
          <div>
            <h3>{formated_places[key].name} - {roles[places[key]-1]}</h3>
            <div className="eyebrow">{formated_places[key].domain}</div>
          </div>
          <div className="eyebrow remove" onClick={async () => await removeManagment(key, props.email)}>
            Remove
          </div>
        </div>
      </div>
        )
      })
    }
  </div>
  </>
  )
}


import API from '../api';

export default interface EventsScheme{
  id: number,
  place_id: number,
  name: string,
  banner: string,
  min_age: number,
  event_start: string,
  event_end: string,
  reservation_start: string,
  reservation_takeover: string,
  ticket_price: string,
  ticket_currency: string,
  event_cancelled: boolean,
  created_at: string,
  updated_at: string
}


// function to get single place data
export const getAllEvents = async (slug: string) => {
  let response = await API.get(`/events/admin/${slug}`);
  return response.data.events;
}
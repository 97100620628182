import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { staffAPI } from './staffAPI';
import StaffScheme from './staffEnum';

// places scheme
interface placesState {
  staff: Record<string, StaffScheme>
}

// initial state
const initialState = {
  staff: {},
} as placesState;

// get all staff
export const getStaff = createAsyncThunk('staff/get', staffAPI.get);
// delete user
export const deleteUser = createAsyncThunk('staff/delete', staffAPI.deleteUser);
// deleteStaff
export const deleteStaff = createAsyncThunk('staff/delete_managment', staffAPI.deleteStaff);

// create user slice
const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getStaff.fulfilled, (state, { payload }) => {
      let staff_obj = {} as any;
      for(let user of payload) {
        if(staff_obj[user.email]) {
          // assign place_id and role
          if(user.place_id)
            staff_obj[user.email].places[user.place_id] = user.role;
        }
        else {
          staff_obj[user.email] = {
            name: user.name,
            email: user.email,
            id: user.id,
            role: user.system_role,
            places: {}
          }
          if(user.place_id)
            staff_obj[user.email].places[user.place_id] = user.role;
        }
      }
      // assing staff_obj to state
      state.staff = staff_obj;
    })
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      delete state.staff[payload];
    })
    builder.addCase(deleteStaff.fulfilled, (state, { payload }) => {
      if(state.staff[payload.email].places[payload.place_id])
        delete state.staff[payload.email].places[payload.place_id];
    })
  },
})


export default staffSlice.reducer;
import { LinkProps } from '../link';
import { StaffIcon, CoctailIcon } from '../icons';

const links: LinkProps[] = [
  {
    to: "/",
    icon: <CoctailIcon className='nav-icon' />,
    text: "Klubovi"
  },
  {
    to: "/users",
    icon: <StaffIcon className='nav-icon' />,
    text: "Korisnici"
  }
];

export default links;
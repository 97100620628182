import { useState, FormEvent, useEffect } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { ContentTitle } from '../../components/titles';
import Loading from '../../components/loading/loading';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Button, Counter, ImageUpload, Input } from '../../components/form';
import '../../../node_modules/flatpickr/dist/flatpickr.min.css';
import SearchLocationInput from '../../components/google-maps/SearchLocationInput';
import { newMessage } from '../../features/messages/messagesSlice';
import { placeUpdateSubmit } from './submit';
import { getAllPlaceData } from '../../helpers/api/clubs';
import { FullPlaceScheme } from '../../features/places/placesEnum';
import config from '../../app/config';

const EditClub = () => {
  const dispatch = useAppDispatch();
  const navigate =  useNavigate();

  // get slug so we can get all data
  let { slug } = useParams();
  
  const { places } = useAppSelector(state => state.places);
  
  // state for form
  const [place, setPlace] = useState({
    name: '',
    domain: '',
    type: 0,
    num_floors: 0,
    slug: '',
    banner: {},
    logo: {},
    oib: '',
    company_name: '',
    contact_number: '',
    owner_email: '',
    email:  '',
    company_address: '',
    country_code: '',
    place_id: -1
  });

  // state for image upload
  const [imgShow, setImgShow] = useState<any>({logo: null, banner: null});
  // state for error
  const [error, setError] = useState('');
  // state for loading
  const [loading, setLoading] = useState(true);

  // load place data
  useEffect(() => {
    if(slug && places[slug]) {
      (async () => {
        try {
          let { place } = await getAllPlaceData(slug) as {place: FullPlaceScheme};
          setPlace({
            name: place.name,
            domain: place.domain,
            type: place.type,
            num_floors: place.num_floors,
            slug: place.slug,
            banner: place.banner,
            logo: place.logo,
            oib: place.oib,
            company_name: place.company_name,
            contact_number: place.contact_number,
            email: place.email,
            company_address: place.company_address,
            country_code: place.country_code,
            place_id: place.id,
            owner_email: ''
          });
          setImgShow({
            banner: config.assets + slug + place.banner, 
            logo: config.assets + slug + place.logo
          });
          setLoading(false);
        }
        catch {
          console.log('404');
        }
      })();
    }
    else {
      setLoading(false);
    }
  }, [slug]);

  if(loading)
    return <Loading />;

  if(slug && !places[slug])
      return <Navigate to="/" replace />

  // function to handle inputs
  const handleChanges = (e: FormEvent<HTMLInputElement>)=>{
    let name = e.currentTarget.name;
    let value = e.currentTarget.value;
    setPlace((prevState) => ({...prevState, [name]: value}));
  }

  // function to handle fileUpload
  const handleFileUpload = (e: FormEvent<HTMLInputElement>) => {
    let name = e.currentTarget.name;
    let fileUploaded = e.currentTarget.files![0];

    // without this page crashing
    if(fileUploaded) {
      setPlace((prevState) => ({...prevState, [name]: fileUploaded}));
      // set image showed
      setImgShow((prevState: any) => ({
        ...prevState,
        [name]: URL.createObjectURL(fileUploaded)
      }));
    }
  }
  
  const handleAddress = (address_object: any) => setPlace((prevState) => ({...prevState, company_address: address_object.address, country_code: address_object.country_code}));
  const floorHandler = (value: number)=> {
    // min age 18
    if(place.num_floors + value >= 0)
      setPlace((prevState) => ({...prevState, num_floors: prevState.num_floors + value}));
  }

  // function to submit changes
  const submitPlace = async () => {
    try {
      setLoading(true);
      // dispatch update data
      let res = await placeUpdateSubmit(place, dispatch, (place.place_id !== -1) ? slug : undefined);
      // set loading to false
      setLoading(false);
      // if error, display error messsage
      if(res.error) {
        setError(res.error);
        dispatch(newMessage({ content: res.error, type: "error" }));
        return;
      }
      if(place.place_id === -1) {
        // dispatch new message
        dispatch(newMessage({ content: `Uspješno ste dodali klub.`, type: 'success' }));
        navigate('/', {replace: true, })
      }
      else { 
        // dispatch new message
        dispatch(newMessage({ content: `Uspješno ste uredili klub.`, type: 'success' }));
        navigate('/'+place.slug, {replace: true});
      }
    }
    catch(err: any) {
      // set loading to false
      setLoading(false);
      // display error message
      setError(err);
    }
  }
  return (
    <>
      <ContentTitle title={(slug) ? "Uređivanje kluba - " + places[slug].name : "Novi klub"} back="Povratak" />
      <section className="content-section">
        <div className="grid">
          <div className="inner-content-grid grid">
            {/* image upload */}
            <div className="edit__box">
              <ImageUpload error={error} imgShow={imgShow.banner} name="banner" fileUpload={handleFileUpload} />
            </div>
            {/* image upload - end */}
            {/* image upload */}
            <div className="edit__box">
              <ImageUpload error={error} imgShow={imgShow.logo} name="logo" fileUpload={handleFileUpload} />
            </div>
            {/* image upload - end */}
            <div className="edit__box">
              <Input error={error} name="name" type="text" placeholder="Naziv" value={place.name} label="Naziv" onChange={handleChanges} />
              <Input error={error} name="slug" type="text" placeholder="Slug" value={place.slug} label={!slug ? "Slug" : "Slug: ( !!! )" } onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <Input error={error} name="company_name" type="text" placeholder="npr. Baud Consulting d.o.o." value={place.company_name} label="Naziv tvrtke" onChange={handleChanges} />
              <Input error={error} name="oib" type="text" placeholder="npr. xxxxxxx" value={place.oib} label="OIB tvrtke" onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <SearchLocationInput name="company_adress" placeholder="Unesite adresu tvrtke" address={place.company_address} assignAddress={handleAddress}  />
              <Counter label="Broj katova" value={place.num_floors} counterHandler={floorHandler}/>
            </div>
            <div className="edit__box">
              <Input error={error} name="domain" type="text" placeholder="Domena" value={place.domain} label="Domena" onChange={handleChanges} />
            </div>
            <div className="edit__box">
              <Input error={error} label="Kontakt broj" name="contact_number" type="string" placeholder="Unesite kontakt broj" value={place.contact_number} onChange={handleChanges}/>
              <Input error={error} name="email" type="text" placeholder="Unesite kontakt email" value={place.email} label="Kontakt email" onChange={handleChanges} />
              { !slug && <Input error={error} name="owner_email" type="text" placeholder="Unesite email vlasnika" value={place.owner_email} label="Email vlasnika" onChange={handleChanges} /> }
            </div>
            <div className="edit__actions">
              <Button text="Spremi promjene" onClick={async () => await submitPlace() }/>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default EditClub;
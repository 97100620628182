import { LoginParams } from './userSlice';
import API from '../../helpers/api';

export const userAPI = {
  login,
  checkSession
}

// function for login
async function login({ email, password, returnURL }: LoginParams, { dispatch, rejectWithValue }:any) {
  try {
    // login request
    let user = await API.post('/user/login', { email: email, password: password });
    if(user.data.user.role !== 1004)
      return rejectWithValue();
    // save user token to local storage
    localStorage.setItem('token', user.data.token);
    // replace window location
    window.location.replace(returnURL === null ? "/" : `/${returnURL}`);
  } catch (err) {
    return rejectWithValue();
  }
}

// function to check if user is logged in
async function checkSession(params: boolean, { dispatch, rejectWithValue }:any) {
  try {
    // get user data
    let user = await API.get('/user/');
    if(user.data.user.role !== 1004)
      return rejectWithValue();
    return user.data;
  } catch (err) {
    return rejectWithValue();
  }
}

// logout function
export function logout() {
  // remove token from local storage
  localStorage.removeItem('token');
  // replace location directly to login (so Private Route doesn't load unneccesserly)
  window.location.replace('/login');
}
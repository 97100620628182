import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { _format } from '../../helpers';
import { placesAPI } from './placesAPI';
import PlacesScheme from './placesEnum';

// places scheme
interface placesState {
  places: Record<string, PlacesScheme>
}

// initial state
const initialState = {
  places: {},
} as placesState;

// get all places
export const getPlaces = createAsyncThunk('places/get', placesAPI.get);
// new place data
export const newPlace = createAsyncThunk('places/new', placesAPI.newPlace);
// update place data
export const updatePlace = createAsyncThunk('places/update', placesAPI.updatePlace);
// suspend place
export const suspendPlace = createAsyncThunk('places/suspend', placesAPI.suspendPlace);
// delete place
export const deletePlace = createAsyncThunk('places/delete', placesAPI.deletePlace);



// create user slice
const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getPlaces.fulfilled, (state, { payload }) => {
      state.places = _format(payload, 'slug');
    })
    builder.addCase(newPlace.fulfilled, (state, { payload }) => {
      state.places[payload.slug] = {
        id: payload.id,
        name: payload.name,
        domain: payload.domain,
        suspend: payload.suspend,
        banner: payload.banner,
        slug: payload.slug
      };
    })
    builder.addCase(updatePlace.fulfilled, (state, { payload }) => {
      if(payload.old_slug !== payload.slug) 
        delete state.places[payload.old_slug];
      
      state.places[payload.slug] = {
        id: payload.place_id,
        name: payload.name,
        domain: payload.domain,
        suspend: payload.suspend,
        banner: payload.banner,
        slug: payload.slug
      };
    })
    builder.addCase(suspendPlace.fulfilled, (state, { payload }) => {
      state.places[payload.slug].suspend = payload.suspend;
    })
    builder.addCase(deletePlace.fulfilled, (state, { payload }) => {
      delete state.places[payload];
    })
  },
})


export default placesSlice.reducer;
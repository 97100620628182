import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { useAppSelector } from '../../app/hooks';

const User = () => {

  const { user } = useAppSelector(state => state.user);
  return (
    <div className="user sidebar__container">
      <div className="user__icon">
        <Profile />
      </div>
      <div>
        <h2 className="user__name">{user.name}</h2>
        <div className="label--gray">{user.email}</div>
      </div>
    </div>
  );
}

export default User;
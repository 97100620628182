import API from '../../helpers/api';

export const staffAPI = {
  get,
  deleteUser,
  deleteStaff
}

// function for getting all use places
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get('/staff/admin');
    // return places
    return req.data.staff;
  } catch (err) {
    return rejectWithValue();
  }
}


// function for deleting user
async function deleteUser(params: any, { dispatch, rejectWithValue }:any) {
  try {
    // fetch delete
    await API.delete(`/user/admin/${params}`);
    // return email
    return params;
  } catch (err) {
    return rejectWithValue();
  }
}

// function for deleting staff
async function deleteStaff(params: {place_id: string, email: string}, { dispatch, rejectWithValue }:any) {
  try {
    // fetch delete
    await API.delete(`/staff/admin`, {data: {
      query: params
    }});
    // return email
    return params;
  } catch (err) {
    return rejectWithValue();
  }
}

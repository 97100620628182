import { useEffect, useState } from "react";
import EventsScheme, { getAllEvents } from "../../../helpers/api/events";
import { DatePicker } from "../../form";
import Loading from "../../loading/loading";
import { SectionTitle } from "../../titles";
import Event from "../../events/event";

// function to sort events
function sortEvents(events: EventsScheme[]): EventsScheme[] {
  events.sort((a, b) => ((new Date(a.event_start)).getTime() - (new Date(b.event_start)).getTime()));
  return events;
}


const ClubEvents = (props: {slug: string}) => {

  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [loaded, setLoaded] = useState(false);
  // state of events
  const [events, setEvents] = useState<EventsScheme[]>([]);
  const [eventsDisplay, setEventsDisplay] = useState<EventsScheme[]>([]);

  useEffect(() => {
    (async () => {
      try {
        let all_events = await getAllEvents(props.slug);
        setEvents(sortEvents(all_events));
        setLoaded(true);
      }
      catch {
        console.log('error with loading events');
      }
    })();
  }, [props.slug]);

  useEffect(() => {
    setEventsDisplay(events.filter((event) => new Date(event.event_start) > dateRange[0] && new Date(event.event_end) < dateRange[1]));
  }, [dateRange]);

  if(!loaded)
    return <Loading />

  const handleDateInput = (name: string, value: Date[] | Date ) => {
    let dates = value as Date[];
    if(dates.length === 2)
      setDateRange(dates);
  }

  
  return (
  <>
  <section className="content-section">
    <SectionTitle title="Događaji" />
      <div className="grid">
        <div className="club-details__box ">
          <div className="input-wrapper">
            <DatePicker name="event_range" range={true} label="Odaberite raspon datuma" placeholder="Datum" onChange={handleDateInput}/>
          </div>
        </div>
      </div>
  </section>
  <section>
    <div className='grid'>
      {
        eventsDisplay.map((event) =>  <Event slug={props.slug} key={event.id} {...event} />)
      }
    </div>
  </section>
  </>
  );
};

export default ClubEvents;
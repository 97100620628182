import { useEffect } from 'react';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Input } from '../../components/form';
import Loading from '../../components/loading/loading';
import StaffPopup from '../../components/popup/staff-popup';
import { ContentTitle } from '../../components/titles';
import Personnel from '../../components/user';
import { getStaff } from '../../features/staff/staffSlice';

const Users = () => {

  const dispatch = useAppDispatch();
  const { staff } = useAppSelector(state => state.staff);
  
  let [selectedUser, setSelectedUser] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  // load users

  useEffect(() => {
    const load_All = async() => {
      
      await dispatch(getStaff(true));
    }
    // load all
    load_All().then( ( ) => setLoaded(true) ).catch( ( err ) => { console.log(err); })
  }, []);

  if(!loaded) // manage loading page
    return <Loading />
    
  // open and close popup 
  const openStaffPopup = (email: string) => {setSelectedUser(email)};
  const closeStaffPopup = () => setSelectedUser('');

  return (
    <>
      <ContentTitle title="Korisnici" />
      <section className="content-section">
      <Input type="text" placeholder='Unesite email' label='Pretrazivanje korisnika' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
        <div className="grid blocked-numbers-grid">
          { !!staff && Object.entries(staff).map(([key, staff]) => { return staff.email.toLowerCase().includes(searchTerm) && <Personnel key={staff.email} {...staff} onClick={() => openStaffPopup(staff.email)}/>}) }
        </div>
      </section>
      <StaffPopup email={selectedUser} isOpened={selectedUser === '' ? false : true} close={closeStaffPopup}/>
    </>
  )
}

export default Users;
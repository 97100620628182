import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import ClubEvents from '../../components/club/single/events';
import MainInfo from '../../components/club/single/main-details';
import { EditIcon } from '../../components/icons';
import Loading from '../../components/loading/loading';
import { ContentTitle } from '../../components/titles';
import { newMessage } from '../../features/messages/messagesSlice';
import { FullPlaceScheme } from '../../features/places/placesEnum';
import { deletePlace, suspendPlace } from '../../features/places/placesSlice';
import { getAllPlaceData } from '../../helpers/api/clubs';

const ClubData = () => {

  const [loaded, setLoaded] = useState(false);
  const [place, setPlace] = useState({} as FullPlaceScheme);
  const { places } = useAppSelector(state => state.places);
  // get slug so we can get all data
  let { slug } = useParams();
  const navigate =  useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        let { place } = await getAllPlaceData(slug!);
        setPlace(place);
        setLoaded(true);
      }
      catch {
        console.log('404');
      }
    })();
  }, [slug]);

  const changePlaceStatus = async (status: boolean) => {
    try {
      setLoaded(false);
      // dispatch update data
      await dispatch(suspendPlace({query: {suspend: status, place_id: place.id, slug: place.slug}}));
      setPlace((prevState) => ({
        ...prevState,
        suspend: status
      }));
      // set loading to false
      setLoaded(true);
      dispatch(newMessage({ content: "Uspjesno ste promjenili status kluba!", type: "success" }));
    } 
    catch {
      setLoaded(true);
      dispatch(newMessage({ content: "Something went wrong!", type: "error" }));
    }
  }

  const removePlace = async () => {
    try {
      setLoaded(false);
      // dispatch update data
      await dispatch(deletePlace({place_id: place.id, slug: place.slug}));
      // set loading to false
      setLoaded(true);
      dispatch(newMessage({ content: "Uspjesno ste obrisali klub.", type: "success" }));
    } 
    catch {
      setLoaded(true);
      dispatch(newMessage({ content: "Something went wrong!", type: "error" }));
    }
  }

  if(!places[slug!])
    return <Navigate to="/" replace />

  if(!loaded)
    return <Loading />

  return (
    <>
      <ContentTitle title={places[slug!].name} back="Svi klubovi" />
      {
        <div className='club__edit' onClick={() => navigate('edit')}><EditIcon />Uredi klub</div>
      }
      <MainInfo {...place} suspendPlace={changePlaceStatus} deletePlace={removePlace} />
      <ClubEvents slug={place.slug} />
    </>
  )
}

export default ClubData;
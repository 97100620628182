import { useAppSelector } from '../../app/hooks';
import Club from '../../components/club';
import NewClub from '../../components/club/new';
import { ContentTitle } from '../../components/titles';

const Clubs = () => {

  const { places } = useAppSelector(state => state.places);

  return (
    <>
      <ContentTitle title="Klubovi" />
      <section className="content-section">
        <div className="grid">
          { !!places && Object.entries(places).map(([key, club]) => { return <Club key={club.id} {...club}/>}) }
          <NewClub />
        </div>
      </section>
    </>
  )
}

export default Clubs;
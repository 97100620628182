import { useState } from 'react';
import Navigation from '../navigation';
import Logout from './logout';
import User from './user';

const Sidebar = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  // function to toggle mobile navbar
  const toggleNavbar = () => setNavbarOpen(!navbarOpen);
  // function to close mobile navbar
  const closeNavbar = () => setNavbarOpen(false);

  return (
    <div className={"sidebar" + (navbarOpen ? " opened" : "")}>
      <div className="sidebar__header">
        <div className="hamburger" onClick={toggleNavbar}></div>
        <div className="logo">TABOO</div>
      </div>
      <div className="sidebar__menu">
        <div>
          <User />
          <Navigation onNavigate={closeNavbar} />
        </div>
        <Logout />
      </div>
    </div>
  )
}

export default Sidebar;
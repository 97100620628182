import { useEffect, useState } from "react";
import PopupWrapper, { PopupActions, PopupProps } from ".";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { newMessage } from "../../features/messages/messagesSlice";
import { FormatPlaces } from "../../features/places/helpers";
import { deleteUser } from "../../features/staff/staffSlice";
import { StaffDetails } from "./staff/main-details";
import { ManagmentSection } from "./staff/managment";

const StaffPopup = (props: PopupProps & {email: string}) => {

  const dispatch = useAppDispatch();
  // staff pull
  const { staff } = useAppSelector(state => state.staff);
  const { places } = useAppSelector(state => state.places);

  let formated_places = FormatPlaces(places);

  // for easier manipulate
  const { email } = props;

  //const [loading, setLoading] = useState(false);


  /*useEffect(() => {
    
  }, [email]);*/

  /*if(loading)
    return <Loading />*/

  const removeUser = async (email: string) => {
    try {
      await dispatch(deleteUser(email));
      dispatch(newMessage({content: "Uspješno ste obrisali korisnika.", type: "success"}));
    }
    catch {
      dispatch(newMessage({content: "Nešto je pošlo po zlu.", type: "error"}));
    }
  }

  return (
    <PopupWrapper opened={props.isOpened}>
      <div>
        <PopupActions close={props.close} />
        {email && <>
        <StaffDetails email={email} name={staff[email].name} />
        <ManagmentSection places={staff[email].places} email={email} formated_places={formated_places} />
        </>}
      </div>
      <div>
      {staff[email] && staff[email].role === 0 && <div className="button--back popup__button" onClick={ async () => { props.close(); await removeUser(email); } }>Obrisi korisnika</div> }
      </div>
    </PopupWrapper>
  )
}

export default StaffPopup;
import config from "../../app/config";
import { formatDate } from "../../helpers";
import EventsScheme from "../../helpers/api/events";

const Event = (props: EventsScheme & { slug:string }) => (
  <div className="event">
    {
      props.event_cancelled ? <div className="event__cancelled">Otkazano</div> : (new Date(props.event_start) < new Date && new Date(props.event_end) > new Date() && <div className="event__going">U tijeku</div>)
    }
    <div className="event__banner">
      <picture>
        <img src={config.assets + props.slug + props.banner} alt="" />
      </picture>
    </div>
    <div className="event__info">
      <div className="eyebrow">{props.event_start && formatDate(new Date(props.event_start), 1)}</div>
      <h3 className="event__h3">{props.name}</h3>
    </div>
    {

      !props.event_cancelled && <div className={(new Date(props.reservation_start) > new Date()) ? "event__status unavailable" : "event__status ongoing"}></div>
    }
  </div>
)

export default Event;
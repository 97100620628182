import { AppDispatch } from '../../../app/store';
import { newPlace, updatePlace } from '../../../features/places/placesSlice';
import { fileCompress, dataURIToBlob } from '../../../helpers/fileCompress';
import { isValidEmail } from '../../../helpers/regex';

interface newPlaceForm {
  name: string,
  domain: string,
  type: number,
  num_floors: number,
  slug: string,
  banner: File | {},
  logo: File | {},
  oib: string,
  company_name: string,
  contact_number: string,
  owner_email: string,
  company_address: string,
  country_code: string,
  place_id: number, 
  email: string,
  old_slug?: string
}
 
export const placeUpdateSubmit = async (updateForm: newPlaceForm, dispatch: AppDispatch, old_slug?: string) => {
  try {
    
    if(updateForm.name === '' || updateForm.domain === '' || updateForm.slug === '' || updateForm.oib === '' || updateForm.company_name === '' || updateForm.contact_number === '' || (updateForm.place_id === -1 && updateForm.owner_email === '') || updateForm.company_address === '' || updateForm.country_code === '')
      return { error: 'Please submit all data.' };


    if(updateForm.place_id === -1 && !isValidEmail(updateForm.owner_email))
      return {error: 'Unesite ispravnu email adresu.'};

    var query = {
      name: updateForm.name,
      domain: updateForm.domain,
      type: updateForm.type,
      num_floors: updateForm.num_floors,
      slug: updateForm.slug,
      oib: updateForm.oib,
      company_name: updateForm.company_name,
      contact_number: updateForm.contact_number,
      owner_email: updateForm.owner_email,
      company_address: updateForm.company_address,
      country_code: updateForm.country_code,
      place_id: updateForm.place_id,
      email: updateForm.email
    };

    let formData = new FormData();

    formData.append('query', JSON.stringify(query));

    if(updateForm.place_id === -1 || typeof updateForm.banner !== "string") {
      try {
        var image = await fileCompress(updateForm.banner as File);
        var new_file = dataURIToBlob(image);
        if(new_file.size > 1.5 * 1000000)
          return {error: 'Uploaded file is too big! Please optimaze file and upload it again.'}
        formData.append('banner', new_file);
      }
      catch {
        throw new Error("Uploadajte banner.");
      }
    }

    if(updateForm.place_id === -1 || typeof updateForm.logo !== "string") {
      try {
        var image = await fileCompress(updateForm.logo as File);
        var new_file = dataURIToBlob(image);
        if(new_file.size > 1.5 * 1000000)
          return {error: 'Uploaded file is too big! Please optimaze file and upload it again.'}
        formData.append('logo', new_file);
      }
      catch {
        throw new Error("Uploadajte logo.");
      }
    }

    if(updateForm.place_id === -1) {
      let res = await dispatch(newPlace({formData, query}));
      if(res.meta.requestStatus === "rejected")
        throw new Error(res.payload);

      return {success: 'Sucessfully created place.'};
    }
    else {
      // assign old slug
      Object.assign(query, {old_slug});
      let res = await dispatch(updatePlace({formData, query}));
      if(res.meta.requestStatus === "rejected")
        throw new Error(res.payload);
      return {success: 'Sucessfully updated place.'};
    }
  } catch(e:any) {
    return { error: e.message };
  }
}
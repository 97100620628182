import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormWrapper, { Button, Input } from "../../components/form";
import { login, errorMsg, successMsg } from "../../features/user/userSlice";

const Login = () => {
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { error, success } = useAppSelector(state => state.user);

  // email and password states
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");

  useEffect(() => {
    // if error exists, and user started typing again, remove error
    if(error !== "") dispatch(errorMsg({ msg: "" }));
    if(success !== "") dispatch(successMsg({ msg: "" }));
  }, [email, password]);

  // function to handle login button click
  const tryToLogin = () => {
    // fill in all data error if needed
    if(email === "" || password === "") return dispatch(errorMsg({ msg: "Molimo unesite sve podatke" }));
    // if all data filled, dispatch login
    dispatch(login({ email: email, password: password, returnURL: searchParams.get('returnURL') }));
  }

  return (
    <FormWrapper
      title="Prijava"
      description="Prijavite se kako bi upravljali svojim klubovima."
      isLogin={true}
      error={error}
      success={success}
    >
      <Input label="E-mail" type="text" name="email" placeholder="Unesite svoj e-mail" onChange={e => setEmail(e.target.value)} onKeyDown={e => e.key === 'Enter' && tryToLogin()} />
      <Input label="Lozinka" type="password" name="password" placeholder="Unesite lozinku" onChange={e => setPassword(e.target.value)} onKeyDown={e => e.key === 'Enter' && tryToLogin()} />
      <Button text="Prijavi se" onClick={tryToLogin} />
    </FormWrapper>
  )
}

export default Login;
import config from "../app/config";
import API from './api';

const weekDays = ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"];
export const roles = ["Hostesa", "Konobar", "Voditelj", "Vlasnik"];

// format date function
export const formatDate = (date: Date, type: number) => {
  if(type === 1)
    return ("0" + date.getDate()).slice(-2) + "." + ("0"+(date.getMonth()+1)).slice(-2) + "." + date.getFullYear() + ", " + weekDays[date.getDay()]
  else if(type === 2)
    return date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear();
  else if(type === 3)
    return ((date.getHours() > 9) ? date.getHours() : '0' + date.getHours()) + ':' + ((date.getMinutes() > 9) ? date.getMinutes() : '0'+date.getMinutes());
  else return '';
}

// function to check if days have passed
export const DaysPast = (date:Date, num_days: number) : boolean => {
  return new Date(date.getTime() + 1000*60*60*24*num_days) > new Date();
}
// function to move time
export const ReverseDate = (date:Date, num_hours: number) : Date => {
  return new Date(date.getTime() + 1000*60*60*num_hours);
}

// function to format array to object
export function _format(array:any, lead:string) {
  var formated:any = {};
  // loop through elements
  for(let item of array) 
    formated[item[lead]] = item;
  // return formated object
  return formated;
}

// function that returns request data directly (simple loading from API)
export async function _load(slug: string) {
  let req = await API.get(config.api+slug);
  return req.data;
}

import PlacesScheme from "./placesEnum";

export const FormatPlaces = (places: Record<string, PlacesScheme>) => {
  var formated_object = {} as Record<string, PlacesScheme>;

  for(let slug in places) {
    formated_object[places[slug].id] = places[slug];
  }

  return formated_object;
}
interface IconScheme {
  className?: string
}

const NotificationsIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.33334 4.16667C8.33334 3.72464 8.50894 3.30072 8.8215 2.98816C9.13406 2.67559 9.55798 2.5 10 2.5C10.442 2.5 10.866 2.67559 11.1785 2.98816C11.4911 3.30072 11.6667 3.72464 11.6667 4.16667C12.6237 4.61919 13.4395 5.32361 14.0267 6.20442C14.6139 7.08523 14.9504 8.10923 15 9.16667V11.6667C15.0627 12.1848 15.2462 12.6809 15.5357 13.1151C15.8252 13.5493 16.2126 13.9095 16.6667 14.1667H3.33334C3.78746 13.9095 4.17485 13.5493 4.46433 13.1151C4.75381 12.6809 4.9373 12.1848 5.00001 11.6667V9.16667C5.04964 8.10923 5.38609 7.08523 5.9733 6.20442C6.56051 5.32361 7.37634 4.61919 8.33334 4.16667" strokeLinecap="round" strokeWidth="1.5" strokeLinejoin="round"/>
    <path d="M7.5 14.1667V15C7.5 15.663 7.76339 16.2989 8.23223 16.7678C8.70107 17.2366 9.33696 17.5 10 17.5C10.663 17.5 11.2989 17.2366 11.7678 16.7678C12.2366 16.2989 12.5 15.663 12.5 15V14.1667" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
  </svg>  
);

const SettingsIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.60417 3.5975C8.95917 2.13417 11.0408 2.13417 11.3958 3.5975C11.4491 3.81733 11.5535 4.02148 11.7006 4.19333C11.8477 4.36518 12.0332 4.49988 12.2422 4.58645C12.4512 4.67303 12.6776 4.70904 12.9032 4.69156C13.1287 4.67407 13.3469 4.60359 13.54 4.48583C14.8258 3.7025 16.2983 5.17417 15.515 6.46083C15.3974 6.65388 15.327 6.87195 15.3096 7.09731C15.2922 7.32267 15.3281 7.54897 15.4146 7.75782C15.5011 7.96666 15.6356 8.15215 15.8073 8.29921C15.9789 8.44627 16.1829 8.55075 16.4025 8.60417C17.8658 8.95917 17.8658 11.0408 16.4025 11.3958C16.1827 11.4491 15.9785 11.5535 15.8067 11.7006C15.6348 11.8477 15.5001 12.0332 15.4135 12.2422C15.327 12.4512 15.291 12.6776 15.3084 12.9032C15.3259 13.1287 15.3964 13.3469 15.5142 13.54C16.2975 14.8258 14.8258 16.2983 13.5392 15.515C13.3461 15.3974 13.1281 15.327 12.9027 15.3096C12.6773 15.2922 12.451 15.3281 12.2422 15.4146C12.0333 15.5011 11.8479 15.6356 11.7008 15.8073C11.5537 15.9789 11.4492 16.1829 11.3958 16.4025C11.0408 17.8658 8.95917 17.8658 8.60417 16.4025C8.5509 16.1827 8.44648 15.9785 8.29941 15.8067C8.15233 15.6348 7.96676 15.5001 7.75779 15.4135C7.54882 15.327 7.32236 15.291 7.09685 15.3084C6.87133 15.3259 6.65313 15.3964 6.46 15.5142C5.17417 16.2975 3.70167 14.8258 4.485 13.5392C4.60258 13.3461 4.67296 13.1281 4.6904 12.9027C4.70785 12.6773 4.67187 12.451 4.58539 12.2422C4.49892 12.0333 4.36438 11.8479 4.19273 11.7008C4.02107 11.5537 3.81714 11.4492 3.5975 11.3958C2.13417 11.0408 2.13417 8.95917 3.5975 8.60417C3.81733 8.5509 4.02148 8.44648 4.19333 8.29941C4.36518 8.15233 4.49988 7.96676 4.58645 7.75779C4.67303 7.54882 4.70904 7.32236 4.69156 7.09685C4.67407 6.87133 4.60359 6.65313 4.48583 6.46C3.7025 5.17417 5.17417 3.70167 6.46083 4.485C7.29417 4.99167 8.37417 4.54333 8.60417 3.5975Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const LockIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.33334 6.66667V5C3.33334 4.55797 3.50893 4.13405 3.82149 3.82149C4.13405 3.50893 4.55798 3.33333 5 3.33333H6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.33334 13.3333V15C3.33334 15.442 3.50893 15.866 3.82149 16.1785C4.13405 16.4911 4.55798 16.6667 5 16.6667H6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 3.33333H15C15.442 3.33333 15.866 3.50893 16.1785 3.82149C16.4911 4.13405 16.6667 4.55797 16.6667 5V6.66667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 16.6667H15C15.442 16.6667 15.866 16.4911 16.1785 16.1785C16.4911 15.866 16.6667 15.442 16.6667 15V13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.5 9.16666H7.5C7.03976 9.16666 6.66666 9.53976 6.66666 10V12.5C6.66666 12.9602 7.03976 13.3333 7.5 13.3333H12.5C12.9602 13.3333 13.3333 12.9602 13.3333 12.5V10C13.3333 9.53976 12.9602 9.16666 12.5 9.16666Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.33334 9.16667V7.5C8.33334 7.05797 8.50893 6.63405 8.82149 6.32149C9.13405 6.00893 9.55798 5.83333 10 5.83333C10.442 5.83333 10.866 6.00893 11.1785 6.32149C11.4911 6.63405 11.6667 7.05797 11.6667 7.5V9.16667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const StaffIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.49999 9.16667C9.34094 9.16667 10.8333 7.67428 10.8333 5.83333C10.8333 3.99238 9.34094 2.5 7.49999 2.5C5.65904 2.5 4.16666 3.99238 4.16666 5.83333C4.16666 7.67428 5.65904 9.16667 7.49999 9.16667Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.5 17.5V15.8333C2.5 14.9493 2.85119 14.1014 3.47631 13.4763C4.10143 12.8512 4.94928 12.5 5.83333 12.5H9.16667C10.0507 12.5 10.8986 12.8512 11.5237 13.4763C12.1488 14.1014 12.5 14.9493 12.5 15.8333V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 2.60833C14.0504 2.79192 14.6859 3.20892 15.1397 3.79359C15.5935 4.37827 15.8399 5.09736 15.8399 5.8375C15.8399 6.57764 15.5935 7.29673 15.1397 7.88141C14.6859 8.46608 14.0504 8.88308 13.3333 9.06667" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.5 17.5V15.8333C17.4958 15.0976 17.2483 14.384 16.7961 13.8037C16.3439 13.2233 15.7124 12.8089 15 12.625" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const CoctailIcon = (props: IconScheme) => (
  <svg width="20" height="20" viewBox="0 0 20 20" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.66667 17.5H13.3333" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.5V17.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.1667 2.5H5.83333L5 8.33333C5 10.8433 7.23833 12.5 10 12.5C10.7271 12.5046 11.4494 12.3819 12.1342 12.1375C12.819 11.8931 13.5476 11.4695 14.1225 10.7833C14.6974 10.0972 15.0085 9.22843 15 8.33333L14.1667 2.5Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const SaveIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.75 2.5H10L12.5 5V11.25C12.5 11.5815 12.3683 11.8995 12.1339 12.1339C11.8995 12.3683 11.5815 12.5 11.25 12.5H3.75C3.41848 12.5 3.10054 12.3683 2.86612 12.1339C2.6317 11.8995 2.5 11.5815 2.5 11.25V3.75C2.5 3.41848 2.6317 3.10054 2.86612 2.86612C3.10054 2.6317 3.41848 2.5 3.75 2.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.5 10C8.19036 10 8.75 9.44036 8.75 8.75C8.75 8.05964 8.19036 7.5 7.5 7.5C6.80964 7.5 6.25 8.05964 6.25 8.75C6.25 9.44036 6.80964 10 7.5 10Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.75 2.5V5H5V2.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const LogoutIcon = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4.5H1M1 4.5L5 1M1 4.5L5 8" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const StepBackIcon = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" className={props.className} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.3905 0.687656C11.563 0.903287 11.528 1.21793 11.3124 1.39044L6.00004 5.64032L0.687696 1.39044C0.472064 1.21793 0.437103 0.903287 0.609609 0.687656C0.782113 0.472025 1.09676 0.437064 1.31239 0.609569L6.00004 4.35969L10.6877 0.609569C10.9033 0.437064 11.218 0.472025 11.3905 0.687656Z" fill="white" fillOpacity="0.7"/>
  </svg>
);

const ClockIcon = (props: IconScheme) => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 14C11.0899 14 14 11.0898 14 7.49998C14 3.91013 11.0899 0.999985 7.5 0.999985C3.91015 0.999985 1 3.91013 1 7.49998C1 11.0898 3.91015 14 7.5 14ZM7.5 15C11.6421 15 15 11.6421 15 7.49998C15 3.35785 11.6421 -1.52588e-05 7.5 -1.52588e-05C3.35786 -1.52588e-05 0 3.35785 0 7.49998C0 11.6421 3.35786 15 7.5 15Z" fill="#EEEEEE"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.5 3.49998C7.77614 3.49998 8 3.72384 8 3.99998V7.3486L9.91603 10.2226C10.0692 10.4524 10.0071 10.7628 9.77735 10.916C9.54759 11.0692 9.23715 11.0071 9.08397 10.7773L7.08397 7.77734C7.02922 7.6952 7 7.5987 7 7.49998V3.99998C7 3.72384 7.22386 3.49998 7.5 3.49998Z" fill="white"/>
  </svg>
);

const SelectArrow = (props: IconScheme) => (
  <svg width="12" height="6" viewBox="0 0 12 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 1L6 5L1 1" stroke="white" strokeOpacity="0.7" strokeLinecap="round"/>
  </svg>
);

const ButtonArrow = (props: IconScheme) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 4.5L11 4.5M11 4.5L7 8M11 4.5L7 1" stroke="#282828" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

const BackArrow = (props: IconScheme) => (
  <svg width="27" height="14" viewBox="0 0 27 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26 7L1 7M1 7L6 13M1 7L6 1" stroke="#C2C2C2" strokeLinecap="round"/>
  </svg>
);

const EditIcon = (props: IconScheme) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_109_3371)">
      <path d="M11.8789 2.94967L9.05025 0.120961C8.97281 0.0435152 8.86771 0 8.75815 0C8.64859 0 8.54349 0.0435152 8.46605 0.120961L0.209726 8.37728C0.135364 8.45175 0.0921246 8.55167 0.0889298 8.65682L0.000191847 11.5743C-0.00327835 11.6882 0.0404572 11.7985 0.120988 11.879C0.198599 11.9566 0.303697 12 0.41309 12C0.417277 12 0.421463 11.9999 0.425704 11.9998L3.3431 11.9109C3.44825 11.9077 3.54822 11.8646 3.62264 11.7902L11.879 3.53393C12.0402 3.37259 12.0402 3.11101 11.8789 2.94967ZM3.15416 11.0901L0.839374 11.1606L0.909825 8.8457L5.92917 3.82631L8.17378 6.07053L3.15416 11.0901ZM8.75804 5.48633L6.51337 3.24216L8.75809 0.997379L11.0025 3.24183L8.75804 5.48633Z" fill="#C2C2C2"/>
    </g>
    <defs><clipPath id="clip0_109_3371"><rect width="12" height="12" fill="white"/></clipPath></defs>
  </svg>
);

export {
  NotificationsIcon,
  SettingsIcon,
  StepBackIcon,
  ButtonArrow,
  CoctailIcon,
  SelectArrow,
  LogoutIcon,
  StaffIcon,
  BackArrow,
  ClockIcon,
  SaveIcon,
  LockIcon,
  EditIcon
}
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { useRoutes, Outlet, Navigate } from "react-router-dom";
import { checkSession } from "./features/user/userSlice";
import Layout from "./containers/layout";
import Clubs from "./containers/clubs";
import Login from "./containers/login";
import { useEffect, useState } from "react";
import Loading from "./components/loading/loading";
import ClubData from "./containers/clubs/single";
import EditClub from "./containers/clubs/new";
import Users from "./containers/users";

// App routes
const App = () => {
  let element = useRoutes([
    { 
      path: "/",
      element: <ProtectedRoute credentials />,
      children: [
        {
          path: "/",
          element: <Layout />,
          children: [
            {
              path: "",
              element: <Clubs />
            },
            {
              path:":slug",
              element: <ClubData />
            },
            {
              path:":slug/edit",
              element: <EditClub />
            },
            {
              path: "new",
              element: <EditClub />
            },
            {
              path: "users",
              element: <Users />
            }
          ]
        }
      ]
    },
    {
      element: <ProtectedRoute />,
      children: [
        {
          path: "/login",
          element: <Login />
        }
      ]
    },
    {
      path: "*",
      element: <Navigate to="/" replace />
    }
  ]);

  return element;
};

// function to protect route
const ProtectedRoute = (props: { credentials?: boolean } ) => {
  const dispatch = useAppDispatch();
  const { isLoggedIn } = useAppSelector(state => state.user);
  const [isLoaded, setLoaded] = useState(false);

  useEffect(() => {
    // if not loaded, call self invocating function to dispatch check user request
    !isLoaded && (async () => {
      // await for API call
      await dispatch(checkSession(true));
      // set isLoaded as true
      setLoaded(true);
    })()
  }, [isLoaded])

  // return null if sill waiting for API response
  if(!isLoaded) return <Loading />;

  // if user is not logged in and credentials are needed, return to login
  if (!isLoggedIn && props.credentials)
    return <Navigate to="/login" replace />;
  // if you are logged in and credentitals are not needed, return to dashboard
  else if(isLoggedIn && !props.credentials)
    return <Navigate to="/" replace />;

  // return Outlet if user is logged in
  return <Outlet />;
};

export default App;
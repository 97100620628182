import config from "../../../app/config";
import { FullPlaceScheme } from "../../../features/places/placesEnum";
import { Button } from "../../form";
import { SectionTitle } from "../../titles";

const MainInfo = (props: FullPlaceScheme & {suspendPlace: (suspend: boolean) => Promise<void>, deletePlace: () => Promise<void>}) => {
  
  return (
  <section className="content-section">
    <SectionTitle title="Osnovne informacije" />
      <div className="grid">
        <div className="club-details__box club-details__banner-holder">
          <div className="club-details__banner">
            <picture>
              <img src={config.assets + props.slug + props.banner} />
            </picture>
          </div>
        </div>
        <div className="club-details__box">
          <div className="grid">
            <div className="club-details__box">
              <h2>Main info</h2>
              <br />
              <h3>Status: {props.suspend ? 'Suspendiran' : 'Aktivan'}</h3>
              <h3>Domena: <a href={'https://'+props.domain}>{props.domain}</a></h3>
              <h3>Tip: {props.type === 0 ? 'Klub' : 'Kafic'}</h3>
              <h3>Slug: {props.slug}</h3>
              <h3>Automatsko blokiranje brojeva: {props.automatic_blocking ? 'Ukljuceno' : 'Iskljuceno'}</h3>
              <h3>Broj katova: {props.num_floors}</h3>
              <h3>Broj stolova: {props.tables_num}</h3>
            </div>
            <div className="club-details__box">
              <h2>Company details</h2>
              <br />
              <h3>Naziv firme: {props.company_name}</h3>
              <h3>OIB: {props.oib}</h3>
              <h3>Porezni broj: {props.country_code}{props.oib}</h3>
              <h3>Adresa firme: {props.company_address}</h3>
              <h3>Adresa kluba: {props.address}</h3>
              <h3>Država: {props.country_code}</h3>
              <h3>Kontakt broj: {props.contact_number}</h3>
            </div>
            <div className="club-details__box">
              <h2>Contact info</h2>
              <br />
              <h3>Email: {props.email}</h3>
              <h3>Facebook: <a href={props.facebook}>{props.facebook}</a></h3>
              <h3>Instagram: <a href={props.instagram}>{props.instagram}</a></h3>
            </div>
            <div className="club-details__box">
              <h2>Akcije</h2>
              <br />
              <Button text={props.suspend ? "Aktiviraj" : "Suspendiraj"} onClick={() => props.suspendPlace(!props.suspend)}/>
              <Button text="Obrisi" onClick={async () => await props.deletePlace()}/>
            </div>
          </div>
        </div>
      </div>
  </section>
  );
};

export default MainInfo;
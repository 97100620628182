import { ReactComponent as CircleAdd } from '../../assets/icons/circle-add.svg';
import { useNavigate } from "react-router-dom";


const NewClub = () => {
  let navigate = useNavigate();
  
  return (
    <div className="new-event" onClick={() => navigate("../new")}>
      <CircleAdd />
      <h2 className="new-event__h2">Novi klub</h2>
    </div>
  );
}

export default NewClub;
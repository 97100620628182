import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { userAPI } from './userAPI';

export interface LoginParams {
  email: string,
  password: string,
  returnURL: string | null
}

interface userState {
  name: string,
  active:boolean,
  email: string,
  role: number
}
const initialState = {
  isLoggedIn: false,
  error: "",
  success: "",
  user: {} as userState
};

// login thunk
export const login = createAsyncThunk('users/login', userAPI.login);

// check session thunk
export const checkSession = createAsyncThunk('users', userAPI.checkSession);

// create user slice
const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    errorMsg: (state, { payload }) => {
      state.error = payload.msg;
    },
    successMsg: (state, { payload }) => {
      state.success = payload.msg;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.error = "";
      state.success = "";
    }).addCase(login.rejected, (state) => {
      state.error = "Pogrešan e-mail ili lozinka";
    })
    builder.addCase(checkSession.fulfilled, (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = payload.user;
    }).addCase(checkSession.rejected, (state) => {
      state.isLoggedIn = false;
    })
  },
})

export const { errorMsg, successMsg } = userSlice.actions;

export default userSlice.reducer;
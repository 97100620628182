import { useAppSelector } from '../../app/hooks';
import Link from '../link';
import links from './links';

interface NavProps{
  onNavigate: () => void
}

const Navigation = (props: NavProps) => {

  return (
    <nav>
      <div className="nav__label label">Menu</div>
      <ul>
        { links.map((link, index) => <Link key={index} {...link} onNavigate={props.onNavigate} />) }
      </ul>
    </nav>
  )
}


export default Navigation;
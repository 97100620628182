import { useNavigate } from "react-router-dom";
import config from "../../app/config";
import PlacesScheme from "../../features/places/placesEnum";

const Club = (props: PlacesScheme) => {

  let navigate = useNavigate();

  return (
    <div className="club" onClick={() => navigate("/" + props.slug)}>
      {
        props.suspend && <div className="club__suspended">Suspendiran</div>
      }
      <div className="club__banner">
        <picture>
          { <img src={config.assets + props.slug + props.banner} alt="" /> }
        </picture>
      </div>
      <div className="club__info">
        <h2 className="club__h2">{props.name}</h2>
        <div className="label--gray">{props.domain}</div>
      </div>
    </div>
  )
}

export default Club;
const config = {
  api: 'https://api.taboo-host.com/', // 'https://api.taboo.hr/',
  assets: 'https://assets.taboo-host.com/', // 'https://assets.taboo.hr',
  icons: 'https://assets.taboo.hr/icons/',
  socket: 'https://socket.taboo-host.com/'
}
/*const config = {
  api: 'http://localhost:8080/',
  assets: 'https://assets.taboo.hr'
}
*/
export default config;
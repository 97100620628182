import API from '../../helpers/api';

export const placesAPI = {
  get,
  newPlace,
  updatePlace,
  suspendPlace,
  deletePlace
}

// function for getting all use places
async function get(params: unknown, { dispatch, rejectWithValue }:any) {
  try {
    // get user places request
    let req = await API.get('/places/admin');
    // return places
    return req.data.places;
  } catch (err) {
    return rejectWithValue();
  }
}


// function for creating place
async function newPlace(params: {formData: any, query: any}, { dispatch, rejectWithValue }:any) {
  try {
    // update place by slug
    let res = await API.post(`/places/new`, params.formData);
    // assign new banner
    params.query.banner = res.data.new_banner;
    params.query.id = res.data.place_id;
    // return update data
    return params.query;
  } catch (err:any) {
    return rejectWithValue(err.response.data.error);
  }
}

// function for updating place
async function updatePlace(params: {formData: any, query: any}, { dispatch, rejectWithValue }:any) {
  try {
    // update place by slug
    let res = await API.put(`/places/admin/update`, params.formData);
    // assign new banner
    params.query.banner = res.data.new_banner;
    // return update data
    return params.query;
  } catch (err:any) {
    return rejectWithValue(err.response.data.error);
  }
}

// function for updating place
async function suspendPlace(params: {query: any}, { dispatch, rejectWithValue }:any) {
  try {
    // update place by slug
    await API.put(`/places/admin/status`, params);
    // return update data
    return params.query;
  } catch (err:any) {
    return rejectWithValue();
  }
}

// function for updating place
async function deletePlace(params:{place_id: number, slug: string}, { dispatch, rejectWithValue }:any) {
  try {
    // update place by slug
    await API.delete(`/places/admin/${params.place_id}`);
    // return update data
    return params.slug;
  } catch (err:any) {
    return rejectWithValue(err.response.data.error);
  }
}
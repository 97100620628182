import { logout } from "../../features/user/userAPI";
import { LogoutIcon } from "../icons";

const Logout = () => {

  return (
    <div className="sidebar__container">
      <div className="button--back" onClick={logout}>ODJAVA<LogoutIcon /></div>
    </div>
  )
}

export default Logout;
import Resizer from "react-image-file-resizer";

export const fileCompress = (file: File) =>
  new Promise((resolve) => {
  Resizer.imageFileResizer(
    file,
    1024,
    1024,
    "WEBP",
    80, // between 0-100 if 100 no compression
    0,
    (uri) => {
      resolve(uri);
    },
    "base64"
  );
});

export const dataURIToBlob = (dataURI:any) => {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
};

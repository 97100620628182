import { EditIcon, BackArrow } from '../icons';

interface PopupWrapperProps {
  opened: boolean,
  children: React.ReactNode
}

interface PopupActionsProps {
  close: () => void,
  edit?: () => void
}

export interface PopupProps {
  isOpened: boolean,
  close: () => void
}

export const PopupActions = (props: PopupActionsProps) => (
  <div className="popup__actions">
    <div className="close-popup hover--translate-right" onClick={props.close}><BackArrow /></div>
    {!!props.edit && <div className="edit hover--opacity" onClick={props.edit}>UREDI<EditIcon /></div>}
  </div>
)

const PopupWrapper = (props: PopupWrapperProps) => (
  <div className={"popup" + (props.opened ? " opened" : "")}>
    <div className="popup__content">
      {props.children}
    </div>
  </div>
)

export default PopupWrapper;
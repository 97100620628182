import flatpickr from "flatpickr";
import { FormEvent, useRef, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg'
import { ButtonArrow } from "../icons";

interface FormWrapperProps {
  children: React.ReactNode,
  title: string,
  description: string,
  isLogin?: boolean,
  error?: string,
  success?: string
}

interface InputProps {
  label?: string,
  type: string,
  placeholder: string,
  name?: string,
  value?: string,
  single?: boolean,
  error?: string,
  defaultValue?: string,
  key?: any,
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void,
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
}

interface CheckboxProps {
  label: string,
  value?: boolean,
  name?: string,
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void,
}

interface ButtonProps {
  text: string,
  type?: string,
  className?: string,
  onClick?(e: React.MouseEvent<HTMLInputElement>): void
}


interface UploadProps {
  imgShow:any, 
  error:string,
  name: string,
  fileUpload: (e: FormEvent<HTMLInputElement>) => void, 
}

interface CounterProps {
  label: string,
  value: number,
  counterHandler: (i:number) => void
}

interface DatePickerProps {
  default_value?: Date,
  name: string,
  label: string,
  placeholder: string,
  min_date?: Date,
  range?: boolean
  onChange: (name: string, date: Date | Date[]) => void,
}

const FormWrapper = (props: FormWrapperProps) => {
  const navigate = useNavigate();

  return (
    <div className="form-wrapper container">
      <div className="form-wrapper__logo">TABOO</div>
      <div className="form">
        <div className="title">{ props.title }</div>
        <p className="form__p">{ props.description }</p>
          {
            props.error !== "" && <p className="form__p error">{ props.error }</p>
          }
          {
            props.success !== "" && <p className="form__p success">{ props.success }</p>
          }
          { props.children }
      </div>
      <div className="login__forgot-password label">
        {
          !!props.isLogin ?
          <div className="hover--underline" onClick={() => navigate('/recovery')}>Zaboravili ste lozinku?</div>
          :
          <div className="hover--underline" onClick={() => navigate('/login')}>Povratak na prijavu</div>
        }
      </div>
    </div>
  )
}

export const Input = (props: InputProps) => {

  if(props.single)
    return <input key={props.key} autoCorrect="off" autoCapitalize="none" data-invalid={props.error && props.error !== '' && props.value === ''} type={props.type} defaultValue={props.defaultValue} name={props.name} value={props.value} placeholder={props.placeholder} onChange={props.onChange} onKeyDown={props.onKeyDown} />

  return (
    <div className="input-wrapper">
      <div className="label input__label">{props.label}</div>
      <input key={props.key} autoCorrect="off" autoCapitalize="none" data-invalid={props.error && props.error !== '' && props.value === ''} defaultValue={props.defaultValue} type={props.type} name={props.name} value={props.value} placeholder={props.placeholder} onChange={props.onChange} onKeyDown={props.onKeyDown} />
    </div>
  );
}

export const Button = (props: ButtonProps) => (
  <div className={"button" + (props.className ? ` ${props.className}` : "")} onClick={props.onClick}>
    { props.text }
    { props.type !== "save" && <ButtonArrow /> }
  </div>
)

export const ImageUpload = (props:UploadProps) => {

  const {imgShow, fileUpload, error} = props;
  const Image = useRef<HTMLInputElement>(null);

  return (
  <div className="edit__banner-wrapper">
    <div className="edit__banner hover--opacity" data-invalid={error !== '' && !imgShow}>
      {imgShow &&<picture>
        <img src={imgShow} />
      </picture>
      }
      <div className="edit__upload-icon" onClick={() => { Image.current!.click(); }}>
        <UploadIcon />
        <input ref={Image} type="file" accept="image/*" name={props.name} onInput={fileUpload} style={{display: 'none'}} />
      </div>
    </div>
  </div>
  );
}

export const CheckBox = (props: CheckboxProps) => (
  <div className="input-wrapper">
    <div className="label input__label">{props.label}</div>
    <input type="checkbox" name={props.name} onChange={props.onChange} checked={props.value} />
  </div>
)

export const Counter = (props: CounterProps) => {

  const { value, label, counterHandler } = props;
  
  return (
  <div className="input-wrapper--row">
    <div className="label input__label">{label}</div>
    <div className="quantity">
      <div className="quantity__minus" onClick={() => {counterHandler(-1)}}></div>
      <div className="quantity__value"><h5>{value}</h5></div>
      <div className="quantity__plus" onClick={() => {counterHandler(1)}}></div>
    </div>
  </div>
  )
}

export const DatePicker = (props: DatePickerProps) => {

  // ref for date picker
  const picker_ref = useRef<HTMLInputElement>(null);

  const {default_value, onChange, name, label, placeholder} = props;

  useLayoutEffect(() => {
    if(picker_ref.current !== null)
      // event start datepicker
      flatpickr(picker_ref.current, {
        minDate: props.min_date,
        defaultDate: default_value,
        dateFormat: "d-m-Y",
        disableMobile: true,
        mode: (props.range) ? "range" : "single",
        onValueUpdate: (e) => onChange(name, (props.range) ? e : e[0])
      });
  }, []);

  return (<>
    <div className="label input__label">{label}</div>
    <input ref={picker_ref} name={name} placeholder={placeholder} readOnly/>
  </>);
}

export default FormWrapper;
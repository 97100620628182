import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface MessageScheme {
  content: string,
  type: string
}

const initialState = {
  messages: {} as Record<string, MessageScheme>
};

// create user slice
const messagesSlice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    newMessage: (state, action: PayloadAction<MessageScheme>) => {
      // generate new message id
      let id = Math.floor(Math.random() * 100) + 1;
      // push new message
      state.messages[id] = action.payload;
    },
    removeMessage: (state, action: PayloadAction<string>) => {
      // remove message
      delete state.messages[action.payload]
    }
  }
})

export const { newMessage, removeMessage } = messagesSlice.actions;

export default messagesSlice.reducer;